import React from 'react';
import styled from 'styled-components';

// const Container = styled.div`
//     text-align: center;
//     background: url('https://images.unsplash.com/photo-1487284122274-e864e9dec2bf?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60');
//     background-size: cover;
//     background-repeat: none;
//     padding: 50px 20px;
//     height: 60vh;
// `
// const HomeDiv = styled.div`
//     background-color: #E8EAEC;
//     display: noflex;
//     justify-content: center;
//     align-items: center;
//     padding: 30px;
//     border-radius: 20px;
//     margin: 0px 120px;
// `

function Dashboard() {

    return (
        <div className='container'>
            <div className='dashboard-home'>
                <h1 className='title'>Devanee Reid</h1>
                <h3 className='title-h3'>Software Engineering Student</h3>
            < br />
                {/* <h2 className='projects'>Projects</h2> */}
            </div>

        </div>
    );
};
export default Dashboard;