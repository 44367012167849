import React from 'react';
import './App.css';
import Dashboard from './Components/Dashboard';

function App() {
  
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default App;
